import React from 'react'
import HeaderStyle1 from '../components/Header/HeaderStyle1'
import CommentImg1 from "../images/reviews/image-3.jpg"
import CommentImg2 from "../images/reviews/self-image.jpg"
import CommentImg3 from "../images/reviews/default-user.png"
import FooterStyle1 from '../components/Footer/FooterStyle1'
import SectionTitle from "../components/SectionTitle/SectionTitle"
import ReviewTable from '../components/ReviewTable'
import TeamStyle5 from "../components/Team/TeamStyle5"
import lang from "../components/lang"
import {Helmet} from "react-helmet"

const BlogPostFullWidth = () => {
    return (
        <>
            <HeaderStyle1 />
            <div className="application">
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Zillow全5星好评经纪人 | 湾区置业专家 湾区Top华人房产经纪 | 熟悉南湾、东湾、三谷市场 | Remax Accord 排名第一 经纪人 | 近12个月成交量超1亿美金</title>
                    <meta name="description" content="一支秉持诚信、负责、专业的湾区TOP 1% 房产经纪人团队，熟悉南湾、东湾、三谷市场，全面考虑客户利益，帮客户选择最适合的房子，专业团队的一条龙服务给客户带来轻松高效的房产交易体验！团队提供房屋贷款、房屋装修、staging、清洁等服务" />
                    <meta name="Keywords" content="湾区,三谷,华人房产经纪,南湾,东湾,湾区卖房,zillow五星经纪人,地产经纪,pleasanton,fremont,cupertino,san jose,mountain view,dublin,palo alto,melo park,livermore,dublin,danville"></meta>
                    <meta name="author" content="Jing Xue" />
                    <link rel="canonical" href="https://www.jingxuehomes.com" />
                </Helmet>
            </div>
            {/* Start Team Section*/}
            <section className="pad30">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                        <SectionTitle title="团队成员" />
                        </div>
                    </div>
                    <TeamStyle5 />
                </div>
            </section>
            {/* End Team Section*/}
            {/* Start Pricing Table Section*/}
            <section className="pad-t60 pad-b10">
                <div className="container">
                <ReviewTable />
                </div>
            </section>
            {/* End Pricing Table Section*/}
            <section className="pad10">
                <div className="container">
                            <div className="single-blog-post">
                                <div className="comment-section">
                                    <h2>{lang === "EN" ? "Zillow Comments": "Zillow全5星好评"}</h2>
                                    <ul className="comment-tree">
                                        <li>
                                            <div className="comment-box">
                                                <img src={CommentImg1} alt="jingxuehomes.com" />
                                                <div className="comment-content">
                                                    <h4>zuser20170708125908197</h4>
                                                    <span>08/17/2020 Bought a Single Family home in 2020 in Danville, CA.</span>
                                                    <p>Being quite new to the US house market, we were looking for an agent with loads of local market experience. Jing has been amazing to deal with. She is very knowledgeable, gave us really useful advice along the way, kept us informed, and at a crucial time of negotiations, she was able to secure an  amazing deal. What also helps is that Jing is a very well know and respected agent, so the seller's agent was willing to work out the deal with us, even though other similar offers were on the table. I can really recommend anyone looking for a house to use Jing as an agent, you will not regret it.</p>
                                                </div>
                                            </div>
                                        </li>

                                        <li>
                                            <div className="comment-box">
                                                <img src={CommentImg2} alt="jingxuehomes.com" />
                                                <div className="comment-content">
                                                    <h4>helenhuang122</h4>
                                                    <span>08/25/2020 Bought a Single Family home in 2019 in Newark, CA.</span>
                                                    <p>Highly recommend our agent Jing to all first time home buyers like us! Thanks to her skills in negotiations and pricing strategy, we were able to purchase our home within the budget that we had.
Her experience and patience made the process smooth and easy for us.</p>
                                                </div>
                                            </div>
                                        </li>

                                        <li>
                                            <div className="comment-box">
                                                <img src={CommentImg3} alt="jingxuehomes.com" />
                                                <div className="comment-content">
                                                    <h4>carriexiao</h4>
                                                    <span>06/14/2020 Bought a Single Family home in 2019 in Pleasanton, CA.</span>
                                                    <p>Jing was my buyer agent, she is very familiar with my target home community (good school!). She found the home at our preferred location and contacted with seller agent before the house come to the market. We have to borrow a jumbo loan, Jing provided couple loan agent resources to us and we could  worked out with one of the bank to get the loan approved within 15 days. Finally Jing managed to purchase our home before it releasing to the market at reasonable price within our budget. Jing's knowledge and loan resources has made our home purchase very pleasant and successful! Jing has also supported our after-sell needs by introducing experienced handyman for small maintenance jobs. She has also helped us to claim for home warranty which was very helpful during the complicated claiming process with home warranty company.</p>
                                                </div>
                                            </div>
                                        </li>

                                        <li>
                                            <div className="comment-box">
                                                <img src={CommentImg3} alt="jingxuehomes.com" />
                                                <div className="comment-content">
                                                    <h4>Ye xiaobing</h4>
                                                    <span>04/09/2020 Sold a Single Family home in 2020 in San Ramon, CA.</span>
                                                    <p>You could not ask for a better agent than Jing Xue. She is very patient, professional, attentive, and FIGHTS for her clients. We felt protected and secure working with her. We will not hesitate to refer Jing to our friends and family (we already have!)</p>
                                                </div>
                                            </div>
                                        </li>

                                        <li>
                                            <div className="comment-box">
                                                <img src={CommentImg3} alt="jingxuehomes.com" />
                                                <div className="comment-content">
                                                    <h4>xiaohucs</h4>
                                                    <span>03/10/2020 Bought and sold a home in 2020 in Alvarado, Union City, CA 94587.</span>
                                                    <p>Jing recently helped us buy our home and we are very satisfied with the whole process. She managed to write a purchase offer with a tight time constraint for the house we bought. Thank for her fantastic negotiation skills and sharpness, our offer was accepted. As always, she is a fast responder to  any concerns and questions we have. To sum up, I highly recommend Jing to anyone who is looking for buying a home.</p>
                                                </div>
                                            </div>
                                        </li>

                                        <li>
                                            <div className="comment-box">
                                                <img src={CommentImg3} alt="jingxuehomes.com" />
                                                <div className="comment-content">
                                                    <h4>tangyang97</h4>
                                                    <span>12/07/2019 Bought and sold a Single Family home in 2019 in Fremont, CA.</span>
                                                    <p>We were very lucky to have Jing as our listing agent. She is very professional, knowledgable, hard working and at the same time very easy to work with. We were extremely satisfied with the entire selling processing, from day 1 we first met her to the final closing date, including the final sale  price.
            
            We really appreciate Jing’s help and highly recommend her to everyone.</p>
                                                </div>
                                            </div>
                                        </li>

                                        <li>
                                            <div className="comment-box">
                                                <img src={CommentImg3} alt="jingxuehomes.com" />
                                                <div className="comment-content">
                                                    <h4>carriezhangli1989</h4>
                                                    <span>12/03/2019 Bought a Single Family home in 2019 in Sunnyvale, CA.</span>
                                                    <p>Jing is very professional, responsible and always willing to help solving problems you encounter. 
            As a first time buyer, in this unpredictable market, my husband and I always got lost on deciding a price when putting an offer. Jing listened to our thoughts patiently, provided her market insights,  and comforted us all the time. Every house was unique, just made an offer with the price you feel comfortable if you were lucky to win it, and also with the price you would never be regretful to miss it. Luckily we bought a satisfied house in a desired area within 2 months with Jing's help.
            Jing has strong negotiable skills, and a great executive ability. It is really amazing to have her be my agent in my house purchasing journey. 
            Highly recommended!</p>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            
                    
                </div>
            </section>
            <FooterStyle1/>
        </>
    )
}

export default BlogPostFullWidth

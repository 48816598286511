import React from "react"

import Zillow from "../images/zillow.png"
import Google from "../images/google.png"
import Sheng from "../images/sheng.png"
import Linkedin from "../images/LinkedIn.png"
import XiaoHongShu from "../images/xiaohongshu.png"
import WeiXin from "../images/weixin.png"
import TopAgentNetwork from "../images/topagentnetwork.png"

import Rating from 'react-rating'
import lang from "../components/lang"

const ReviewTable = () => {
  return (
    <div className="row">
      <div className="col-md-3">
        <div className="pricing-box wow fadeInUp" data-wow-duration="1s">
          <div
            className="pricing-head white" style={{ backgroundColor: "#f3f3f3" }}
          >
            <img src={Zillow} alt="" style={{maxHeight:50, width:'auto'}} />
            <br/>
            <br/>
            <Rating totalSymbols={5} readonly initialRating={5}/>
          </div>
          <div className="pricing-body">
            <ul>
              <li>70+ 好评</li>
              <li>全5星好评</li>
            </ul>
          </div>
          <div className="pricing-footer">
            <a href="https://www.zillow.com/profile/Jing-Xue/">{lang === "EN" ? "Know More": "了解更多"}</a>
          </div>
        </div>
      </div>
      <div className="col-md-3">
        <div
          className="pricing-box wow fadeInUp"
          data-wow-duration="1s"
          data-wow-delay=".5s"
        >
          <div
            className="pricing-head white" style={{ backgroundColor: "#f3f3f3" }}
          >
            <img src={Google} alt="" style={{maxHeight:50, width:'auto'}} />
            <br/>
            <br/>
            <Rating totalSymbols={5} readonly initialRating={5}/>
          </div>
          <div className="pricing-body">
            <ul>
              <li>30+ 好评</li>
              <li>全5星好评</li>
            </ul>
          </div>
          <div className="pricing-footer">
            <a href="https://www.google.com/search?q=jing+xue+realtor&#lrd=0x808feb5994a4c681:0xdb249dd99ecaacc9,1,,,">{lang === "EN" ? "Know More": "了解更多"}</a>
          </div>
        </div>
      </div>
      <div className="col-md-3">
        <div
          className="pricing-box wow fadeInUp"
          data-wow-duration="1s"
          data-wow-delay="1s"
        >
          <div
            className="pricing-head white" style={{ backgroundColor: "#f3f3f3" }}
          >
            <img src={Sheng} alt="" style={{maxHeight:50, width:'auto'}} />
            <br/>
            <br/>
            <Rating totalSymbols={5} readonly initialRating={5}/>
          </div>
          <div className="pricing-body">
            <ul>
              <li>30+ 好评</li>
              <li>全5星好评</li>
            </ul>
          </div>
          <div className="pricing-footer">
            <a href="https://www.dealmoon.com/guide/946734">{lang === "EN" ? "Know More": "了解更多"}</a>
          </div>
        </div>
      </div>
      <div className="col-md-3">
        <div
          className="pricing-box wow fadeInUp"
          data-wow-duration="1s"
          data-wow-delay="1s"
        >
          <div
            className="pricing-head white" style={{ backgroundColor: "#f3f3f3" }}
          >
            <img src={Linkedin} alt="" style={{maxHeight:50, width:'auto'}} />
            <br/>
            <br/>
            <Rating totalSymbols={5} readonly initialRating={5}/>
          </div>
          <div className="pricing-body">
            <ul>
              <li>30+ 好评</li>
              <li>全5星好评</li>
            </ul>
          </div>
          <div className="pricing-footer">
            <a href="https://www.linkedin.com/in/jing-xue-5b616a86/">{lang === "EN" ? "Know More": "了解更多"}</a>
          </div>
        </div>
      </div>
      <div className="col-md-3">
        <div
          className="pricing-box wow fadeInUp"
          data-wow-duration="1s"
          data-wow-delay="1s"
        >
          <div
            className="pricing-head white" style={{ backgroundColor: "#f3f3f3" }}
          >
            <img src={XiaoHongShu} alt="" style={{maxHeight:50, width:'auto'}} />
            <br/>
            <br/>
            <Rating totalSymbols={5} readonly initialRating={5}/>
          </div>
          <div className="pricing-body">
            <ul>
              <li>30+ 好评</li>
              <li>全5星好评</li>
            </ul>
          </div>
          <div className="pricing-footer">
            <a href="https://www.linkedin.com/in/jing-xue-5b616a86/">{lang === "EN" ? "Know More": "了解更多"}</a>
          </div>
        </div>
      </div>
      <div className="col-md-3">
        <div
          className="pricing-box wow fadeInUp"
          data-wow-duration="1s"
          data-wow-delay="1s"
        >
          <div
            className="pricing-head white" style={{ backgroundColor: "#f3f3f3" }}
          >
            <img src={WeiXin} alt="" style={{maxHeight:50, width:'auto'}} />
            <br/>
            <br/>
            <Rating totalSymbols={5} readonly initialRating={5}/>
          </div>
          <div className="pricing-body">
            <ul>
              <li>30+ 好评</li>
              <li>全5星好评</li>
            </ul>
          </div>
          <div className="pricing-footer">
            <a href="https://www.linkedin.com/in/jing-xue-5b616a86/">{lang === "EN" ? "Know More": "了解更多"}</a>
          </div>
        </div>
      </div>
      <div className="col-md-3">
        <div
          className="pricing-box wow fadeInUp"
          data-wow-duration="1s"
          data-wow-delay="1s"
        >
          <div
            className="pricing-head white" style={{ backgroundColor: "#f3f3f3" }}
          >
            <img src={TopAgentNetwork} alt="" style={{maxHeight:50, width:'auto'}} />
            <br/>
            <br/>
            <Rating totalSymbols={5} readonly initialRating={5}/>
          </div>
          <div className="pricing-body">
            <ul>
              <li>30+ 好评</li>
              <li>全5星好评</li>
            </ul>
          </div>
          <div className="pricing-footer">
            <a href="https://www.linkedin.com/in/jing-xue-5b616a86/">{lang === "EN" ? "Know More": "了解更多"}</a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ReviewTable

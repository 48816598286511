import React from 'react'
import TeamStyle4 from "./TeamStyle4"
import ComingSoon from "../../images/coming-soon.jpg"
import ComingSoonFree from "../../images/coming-soon-free.jpg"
import ComingSoonDiscount from "../../images/coming-soon-discount.jpg"
import Jing from "../../images/jingteam.jpg"
import Susan from "../../images/susan.jpg"

const TeamStyle5 = () => {
    return (
        <div className="row">
            <div className="col-md-3">
                <TeamStyle4
                    teamImg={Jing}
                    name="Jing Xue"
                    designation="湾区Top房产经纪"
                    details="2021年成交量超1亿美金，精通中英文。拥有丰富的南湾、东湾、三谷经纪人网络，可在off market解决您的买卖需求。"
                />
            </div>
            <div className="col-md-3">
                <TeamStyle4
                    teamImg={Susan}
                    name="Su Jia"
                    designation="金牌房产经纪"
                    details="专业市场分析、精准预估房价、擅长沟通谈判，在抢房大战中以合适的价格，助你拿下心仪的房子"
                />
            </div>
            <div className="col-md-3">
                <TeamStyle4
                    teamImg={ComingSoonFree}
                    name="Amy Zhang"
                    designation="交易助理"
                    details="处理房地产交易事务的协调工作。"
                />
            </div>
            <div className="col-md-3">
                <TeamStyle4
                    teamImg={ComingSoonDiscount}
                    name="May Hu"
                    designation="湾区资深贷款经纪"
                    details="有多年银行从业经验，专攻贷款难题，熟悉各种贷款方案，凭借丰富的贷款经验和税务知识，为客人度身定制最优的贷款方案。"
                />
            </div>
            <div className="col-md-3">
                <TeamStyle4
                    teamImg={ComingSoonDiscount}
                    name="Jian Wang"
                    designation="湾区资深贷款经纪"
                    details="擅长从100多家Lender里帮客户选择最低利率，最多反点的策略。 多年贷款经验，随时解决客户问题，为客户争取最大利益。"
                />
            </div>
            <div className="col-md-3">
                <TeamStyle4
                    teamImg={ComingSoonFree}
                    name="Xin Zhang"
                    designation="湾区专业Staging团队"
                    details="提供专业房屋家居布置服务。室内布置的摆设、颜色与家俱相互搭配调和，不仅能让您的房子增添奢华气息，还能立即为房屋空间提升品味和质感！旨在最大化销售价值！"
                />
            </div>
            <div className="col-md-3">
                <TeamStyle4
                    teamImg={ComingSoonDiscount}
                    name="Zijian"
                    designation="湾区专业装修团队"
                    details="24/7 全年运营承诺绝不拖工，客户至上、质量第一，湾区专业的装修团队，对自己的要求也是精益求精，同时做工非常认真可靠。居民建筑的厨房、卫浴装修工程等，均有丰富的经验。"
                />
            </div>
            <div className="col-md-3">
                <TeamStyle4
                    teamImg={ComingSoonFree}
                    name="Sarah"
                    designation="院子维护 & Deep Clean"
                    details="服务整个旧金山湾区，提供家庭清洁，搬家清洁，卖房清洁，水洗地毯等服务。，给大家带来高质量清洁服务。"
                />
            </div>
        </div>
    )
}

export default TeamStyle5
